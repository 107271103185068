<template>
  <v-row class="page page--home">
    <v-col cols="12" md="5">
      <h1 class="page__title">
        {{ $t('home_title') }}
      </h1>
      <p class="page__subtitle">
        {{ $t('home_text') }}
      </p>

      <div class="home__button-wrap">
        <v-btn
          class="home__button pkmn-button pkmn-button--has-border pkmn-button--full-width-mobile"
          color="primary"
          large
          @click="$router.push('/employees')"
        >
          {{ $t('home_add_employee') }}
        </v-btn>
        <v-btn
          v-if="auth.checkPermission('add-group')"
          class="home__button pkmn-button pkmn-button--full-width-mobile"
          color="primary"
          large
          outlined
          @click="$router.push('/groups')"
        >
          {{ $t('home_add_group') }}
        </v-btn>
        <v-btn
          class="home__button pkmn-button pkmn-button--full-width-mobile"
          color="primary"
          large
          outlined
          @click="$router.push('/transactions')"
        >
          {{ $t('home_view_transaction') }}
        </v-btn>
        <v-btn
          class="home__button home__button--logout pkmn-button pkmn-button--full-width-mobile"
          color="primary"
          large
          outlined
          @click="auth.logout"
        >
          {{ $t('g_log_out') }}
        </v-btn>
      </div>
    </v-col>

    <v-col cols="12" md="7">
      <img
        class="home__illustration"
        src="@/assets/login-home-illustration.png"
      />
    </v-col>
  </v-row>
</template>

<script>
import auth from '@/auth'
import helpers from '@/helpers'

export default {
  name: 'HomeView',
  data() {
    return {
      auth: auth
    }
  },
  methods: {
    async loadData() {
      // load user and company data first to speed up other page loading
      if (
        !this.$store.state.userData || // in the first time
        this.$store.state.isNewDashboardDataRequest
      ) {
        // after company switch
        await helpers.fetchDashboardData()

        // mark as the loading done
        this.$store.dispatch('setRequestNewDashboardData', false)
      }
    }
  },
  async mounted() {
    await this.loadData()
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.home__button-wrap {
  margin-top: 40px;
  padding-top: 20px;
}

.home__button {
  display: block;
  margin: 16px 0;
  text-align: center;
  width: 270px;

  &--logout {
    margin-top: 32px;
  }
}

.home__illustration {
  width: 100%;
}
</style>
